import { useRef } from 'react';
import { useMediaQuery, useResizeObserver } from 'usehooks-ts';
import type { HalfAndHalfProps } from './half-and-half';
import cx from 'classnames';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import { BrandTextHeader } from '@dx-ui/osc-brand-text-header';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { YouTubeVideo } from '@dx-ui/osc-youtube-video';
import { ResponsiveImage } from '@dx-ui/osc-responsive-image';
import { BrandLink } from '@dx-ui/osc-brand-buttons';
import {
  Video,
  VideoCaptions,
  VideoControls,
  VideoTranscript,
  useVideoPlayerClasses,
  useVideoPlayer,
  VideoPlayerMarkup,
} from '@dx-ui/osc-video-player';
import { CarouselSingle } from '@dx-ui/osc-carousel';
import { useRect } from '@dx-ui/utilities-use-rect';
import { Pagination } from '@dx-ui/osc-pagination';
import { useCycle } from '@dx-ui/osc-cycle';
import type { AgentStatus } from '@dx-ui/framework-conductrics';
import { sendReward, useConductricsSelection, Status } from '@dx-ui/framework-conductrics';

function sendHalfAndHalfCtaReward(isMobile: boolean) {
  if (isMobile) {
    sendReward('g-BzOBPhK7V7');
  }
}
function useHalfAndHalfMobileLayoutTest(status: AgentStatus | undefined) {
  const { selection, isLoaded } = useConductricsSelection('a-sYzuWw8FXGMx', status);
  return {
    variantA: isLoaded && selection?.choice === 'A',
    variantB: isLoaded && selection?.choice === 'B',
  };
}

export type THalfAndHalfContentProps = HalfAndHalfProps & {
  isPositionOdd?: boolean;
  activeIndex?: number;
  count?: number;
  isFullCarousel?: boolean;
  isFullScreen?: boolean;
  mediaRatio?: '50-50' | '60-40' | '70-30';
};

function CarouselControls() {
  const { cycleId, active, total, onNextClick, onPreviousClick } = useCycle();
  return (
    <div
      data-e2e="arrows"
      className="pointer-events-none inset-0 justify-end whitespace-nowrap text-lg xl:flex-row"
      id={cycleId}
    >
      <Pagination
        controls={cycleId}
        label="Carousel"
        hideLabel
        current={active}
        total={total}
        onNextClick={onNextClick}
        onPreviousClick={onPreviousClick}
        loop
      />
    </div>
  );
}

const HalfAndHalfContent = ({
  imageUrl,
  captionData,
  headline,
  description,
  copy,
  imageAltText,
  id,
  link,
  hasParallax,
  isPositionOdd,
  media,
  hasImageCarousel,
  carouselImages,
  speed = -10,
  cmsDocumentControl,
  brandComponentTheme,
  brandCode,
  index,
  activeIndex,
  count,
  isFullCarousel,
  isFullScreen,
  mediaRatio = '50-50',
}: THalfAndHalfContentProps) => {
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });
  const wrapperElement = useRef<React.ElementRef<'div'>>(null);
  const isVideo = Boolean(media?.video?.url || media?.multimedia?.videoUrl);
  const isYouTubeShort = media?.video?.source === 'youtube-short';
  const isPortrait = media?.multimedia?.orientation === 'portrait';
  const isLandscape = media?.multimedia?.orientation === 'landscape';
  const isVerticalVideo = isVideo && (isYouTubeShort || isPortrait);
  const videoUrl = media?.multimedia?.videoUrl || '';
  const isCinemagraph = !!media?.multimedia?.altText;
  const isMuteVisible = !isCinemagraph && !media?.multimedia?.isMuted;
  const playerProps = useVideoPlayer({
    ...media?.multimedia,
    videoUrl,
    wrapperElement,
  });
  const playerClassNames = useVideoPlayerClasses({ isLandscape });
  const hasActiveTranscript = Boolean(playerProps.videoTranscriptProps?.activeTranscript);
  const isLarge = useMediaQuery('(min-width: 1024px)');
  const videoSize = useResizeObserver({ ref: playerProps.videoProps.videoElement });
  const height = videoSize?.height ?? 0;
  const transcriptStyle = isLarge && !isVerticalVideo ? { height: `${height}px` } : undefined;
  // Hampton theme overrides - remove in NHCBP-5753
  const isHamptonBrand = brandCode === 'HP';
  const isDarkTheme = brandComponentTheme === 'dark';
  const isTabletAndMobile = useMediaQuery(`(max-width:1023px)`);
  const isMobile = useMediaQuery('(max-width: 640px)');
  const mobileLayout = useHalfAndHalfMobileLayoutTest(isMobile ? Status.OK : Status.PENDING);
  const Transcript = hasActiveTranscript ? (
    <VideoTranscript
      {...playerProps.videoTranscriptProps}
      style={transcriptStyle}
      brandComponentTheme={brandComponentTheme}
      className={cx('overflow-hidden p-4 lg:mb-6', {
        '[&>ol]:lg:max-h-full [&>ol]:lg:pb-16': !isVerticalVideo,
        '[&>ol]:lg:max-h-64': isVerticalVideo,
      })}
    />
  ) : null;
  const LongDescription = copy ? (
    <BrandTextBody
      // eslint-disable-next-line tailwindcss/no-custom-classname -- tracked in NHCBP-5929
      className="text-text brand-ou:text-primary brand-up:mb-12 brand-up:text-center !text-xl"
      brandComponentTheme={brandComponentTheme}
    >
      {copy}
    </BrandTextBody>
  ) : null;
  const CtaLink =
    link?.label && link?.url ? (
      <div
        className={cx('pt-6', {
          'mb-4': isTabletAndMobile && mobileLayout.variantA,
        })}
      >
        <BrandLink
          onClick={() => sendHalfAndHalfCtaReward(isMobile)}
          label={link.label}
          isNewWindow={link.isNewWindow}
          showNewWindowIcon={link.isNewWindow}
          url={link.url}
          brandComponentTheme={brandComponentTheme}
          data-conductrics-goal={link.experimentationConfiguration?.goal}
          data-conductrics-value={link.experimentationConfiguration?.value}
        />
      </div>
    ) : null;
  const Headline = headline ? (
    <BrandTextHeader
      className={cx('sm:!heading-3xl tracking-tight lg:mb-4', {
        '!text-text-inverse': isHamptonBrand && isDarkTheme,
        'mb-4': isTabletAndMobile && mobileLayout.variantB,
      })}
    >
      {headline}
    </BrandTextHeader>
  ) : null;
  const ShortDescription = description ? (
    <BrandTextBody
      // eslint-disable-next-line tailwindcss/no-custom-classname -- tracked in NHCBP-5929
      className="brand-ou:text-primary py-4 font-sans !text-xl lg:pt-0"
      brandComponentTheme={brandComponentTheme}
    >
      {description}
    </BrandTextBody>
  ) : null;

  const ImgVideoContent = (
    <div
      data-testid="halfNHalfMedia"
      className={cx({
        'lg:w-4/12 max-w-sm': isVerticalVideo,
        'lg:w-1/2': !isVerticalVideo && mediaRatio === '50-50',
        'lg:w-3/5': !isVerticalVideo && mediaRatio === '60-40',
        'lg:w-2/3': !isVerticalVideo && mediaRatio === '70-30',
      })}
    >
      {imageUrl && !isVideo && !hasImageCarousel ? (
        <div className="relative overflow-hidden py-2 lg:py-0" ref={ref}>
          <ParallaxBanner className="aspect-[3/2]" disabled={!hasParallax || isVideo}>
            <ParallaxBannerLayer
              speed={speed}
              expanded={false}
              scale={[1.2, 1.2]}
              disabled={!hasParallax || isVideo}
            >
              <ResponsiveImage
                aspectRatio="3:2"
                id={id}
                imageUrl={imageUrl}
                altText={imageAltText ?? ''}
                width={rect?.width ?? 0}
                captionData={captionData}
                className="image-corner-radius"
              />
            </ParallaxBannerLayer>
          </ParallaxBanner>
        </div>
      ) : null}

      {isVideo ? (
        <div className="relative py-2 lg:py-0">
          {media?.video?.url && media.video.title ? (
            <YouTubeVideo
              {...media.video}
              wrapperClassName="overflow-hidden"
              id={media.video.title.trim()}
              isVertical={isVerticalVideo}
              isAutoPlay={false}
            />
          ) : media?.multimedia?.videoUrl ? (
            <>
              <div
                className={cx(playerClassNames.wrapperClassNames, 'mb-8', {
                  'sm:mb-0': !isVerticalVideo,
                  'lg:mb-0': isVerticalVideo,
                })}
              >
                <Video
                  {...playerProps.videoProps}
                  captionData={media?.multimedia?.captionData}
                  isAutoPlay={media?.multimedia?.isAutoPlay}
                  posterImageUrl={media?.multimedia?.posterImageUrl}
                  videoId={media?.multimedia?.videoId}
                  videoUrl={videoUrl}
                  role={isCinemagraph ? 'img' : undefined}
                  aria-label={isCinemagraph ? media?.multimedia?.altText : undefined}
                />
                <VideoCaptions
                  {...playerProps.videoCaptionProps}
                  brandComponentTheme={brandComponentTheme}
                  className={playerClassNames.captionClassNames}
                />
                <VideoControls
                  buttonOptions={{
                    mute: { isVisible: isMuteVisible },
                  }}
                  videoVariant={isCinemagraph ? 'cinemagraph' : undefined}
                  {...playerProps.videoControlsProps}
                  brandComponentTheme={brandComponentTheme}
                  className={playerClassNames.controlsClassNames}
                />
              </div>
              <div className="lg:hidden">{Transcript}</div>
            </>
          ) : null}
        </div>
      ) : null}

      {hasImageCarousel && carouselImages && !isVideo ? (
        <CarouselSingle
          images={carouselImages}
          showAlternateControls={false}
          className="image-corner-radius"
        />
      ) : null}
    </div>
  );

  const DefaultLayout = (
    <>
      <div
        data-testid="halfNHalfContent"
        className={cx({
          'lg:w-1/2': mediaRatio === '50-50',
          'lg:w-2/5': mediaRatio === '60-40',
          'lg:w-1/3': mediaRatio === '70-30',
          'lg:ps-10': isFullScreen && isPositionOdd,
        })}
      >
        <div className="hidden lg:block">{Transcript}</div>
        {Headline}
        {ShortDescription}
        <div className="hidden lg:block">
          {LongDescription}
          {CtaLink}
        </div>
      </div>
      {ImgVideoContent}
      <div className="lg:hidden">
        {LongDescription}
        {CtaLink}
      </div>
    </>
  );
  const MobileLayoutVariantAB =
    isTabletAndMobile && (mobileLayout.variantA || mobileLayout.variantB) ? (
      <>
        {Headline}
        {mobileLayout.variantB ? ImgVideoContent : null}
        {ShortDescription}
        {CtaLink}
        {mobileLayout.variantA ? ImgVideoContent : null}
      </>
    ) : null;

  return (
    <div
      className={cx('relative py-12', {
        container: !isFullScreen,
        'px-4 md:px-8 lg:px-0': isFullScreen,
        hidden: isFullCarousel && index !== activeIndex,
      })}
      ref={wrapperElement}
      data-testid={
        isFullCarousel && activeIndex !== undefined
          ? `halfNHalf-slide-${activeIndex + 1}-of-${count}`
          : undefined
      }
    >
      {media?.multimedia?.markupSchemas ? (
        <VideoPlayerMarkup markupSchemas={media.multimedia.markupSchemas} />
      ) : null}

      <div
        className={cx('relative w-full justify-center gap-12 lg:flex', {
          'flex-row-reverse': !isPositionOdd,
          'items-center': !hasActiveTranscript,
          'items-start': hasActiveTranscript,
          'max-w-sm mx-auto lg:max-w-full': isVerticalVideo,
        })}
        data-testid="halfNHalfContentWrapper"
      >
        {MobileLayoutVariantAB || DefaultLayout}
      </div>
      {isFullCarousel && (count ?? 0) > 1 && (
        <div
          className={cx('relative -start-7 mt-6 flex lg:absolute lg:bottom-12 lg:mt-0', {
            'lg:start-3': isPositionOdd,
            'lg:end-10 lg:start-auto': !isPositionOdd,
          })}
        >
          <CarouselControls />
        </div>
      )}
      {cmsDocumentControl}
    </div>
  );
};

export { HalfAndHalfContent };
export default HalfAndHalfContent;
