import type { Multimedia as AwardMultimedia } from 'dx-shared-schema/dist/types/couchbase/award';
import type { Multimedia as BrandMultimedia } from 'dx-shared-schema/dist/types/couchbase/brand';
import type { Multimedia as EventMultimedia } from 'dx-shared-schema/dist/types/couchbase/event';
import type { Multimedia as PartnerMultimedia } from 'dx-shared-schema/dist/types/couchbase/partner';
import type { Multimedia as LocationMultimedia } from 'dx-shared-schema/dist/types/couchbase/location';
import type { Multimedia as PlaceMultimedia } from 'dx-shared-schema/dist/types/couchbase/place';
import type { Multimedia as StoryMultimedia } from 'dx-shared-schema/dist/types/couchbase/story';
import type { VideoPlayer } from './video-player';
import type { VideoPlayerMarkup } from './video-player.markup';
import type { VideoPlayerProps } from './hooks/use-video-player';
import { env } from '@dx-ui/framework-env';
import groupBy from 'lodash/groupBy';

type AllMultimedia =
  | AwardMultimedia
  | BrandMultimedia
  | EventMultimedia
  | PartnerMultimedia
  | LocationMultimedia
  | PlaceMultimedia
  | StoryMultimedia;

type Metadata = AllMultimedia['multimediaMetadata'][number];

// NOTE: Re-construct these types to resolve the incompatible types between dx-shared-schema and Core+ types
export type Multimedia = Omit<AllMultimedia, 'multimediaMetadata' | 'orientation'> & {
  multimediaMetadata: MultimediaMetadatum[];
  orientation: AllMultimedia['orientation'];
};

export type MultimediaMetadatum = Omit<Metadata, 'language' | 'type'> & {
  language: string;
  type: Metadata['type'];
};

type MappedPage = { brandCode?: string; pathname?: string };

export function mapMultimediaListToVideoProps({
  multimedia,
  mappedPage,
  groupIdentifier = multimedia[0]?.groupIdentifier || null,
}: {
  multimedia: Multimedia[];
  mappedPage: MappedPage;
  groupIdentifier?: string | null;
}): React.ComponentProps<typeof VideoPlayer> {
  const [firstVideo, ...additionalVideos] = groupMultimedia(multimedia, groupIdentifier);
  const videoTrack = mapMultimediaToVideoTrack(firstVideo);

  const markupSchemas: Required<React.ComponentProps<typeof VideoPlayerMarkup>['markupSchemas']> =
    multimedia
      .filter((multimedia) => multimedia.description)
      .map((multimedia) =>
        mapVideoMarkup(multimedia as ValidatedVideoMarkupMultimedia, mappedPage)
      );

  return {
    ...videoTrack,
    audioTracks: additionalVideos.map(mapMultimediaToVideoTrack),
    isAutoPlay: firstVideo.videoAutoPlay,
    isMuted: firstVideo.noAudio,
    altText: firstVideo.altText ?? '',
    orientation: firstVideo.orientation,
    posterImageUrl: firstVideo.posterImageUrl,
    markupSchemas,
  };
}

function mapMultimediaToVideoTrack(multimedia: Multimedia): Omit<VideoPlayerProps, 'audioTracks'> {
  return {
    videoName: multimedia.videoName,
    videoUrl: multimedia.videoUrl,
    videoGroup: getVideoGroup(multimedia),
    videoLabel: multimedia.audioTrackLabel || multimedia.alternativeVideoLabel,
    //description field is an interim measure. Here is long term solution https://jira.hilton.com/browse/NHCBP-5914
    ...(multimedia.description && { altText: multimedia.description }),
    captionTracks: filterByTrackType(multimedia.multimediaMetadata, 'captionTrack'),
    transcriptTracks: filterByTrackType(multimedia.multimediaMetadata, 'transcriptTrack'),
  };
}

function filterByTrackType(
  multimediaMetadata: MultimediaMetadatum[],
  type: MultimediaMetadatum['type']
) {
  return multimediaMetadata.filter((metadata) => metadata.type === type);
}

function groupMultimedia(multimediaList: Multimedia[], groupIdentifier: string | null) {
  return (
    groupBy(multimediaList, (multimedia) => multimedia.groupIdentifier || null)[
      String(groupIdentifier)
    ] || []
  ).toSorted((a, b) => Number(b.primaryVideo ?? 0) - Number(a.primaryVideo ?? 0)) as [
    Multimedia,
    ...Multimedia[]
  ];
}

function getVideoGroup(multimedia: Multimedia): NonNullable<VideoPlayerProps['videoGroup']> {
  const isExtended = Boolean(multimedia.extended || multimedia.extendedAudioDescription);
  return isExtended ? 'extended' : 'standard';
}

type NoUndefinedField<T> = { [P in keyof T]: Exclude<T[P], null | undefined> };

type ValidatedVideoMarkupMultimedia = NoUndefinedField<
  Required<
    Pick<
      Multimedia,
      'videoName' | 'description' | 'posterImageUrl' | 'uploadDatetime' | 'videoDuration'
    >
  >
>;

function mapVideoMarkup(multimedia: ValidatedVideoMarkupMultimedia, mappedPage: MappedPage) {
  const { brandCode = 'WW', pathname = '' } = mappedPage;
  const baseUrl = env('OHW_BASE_URL', 'https://www.hilton.com');
  return {
    name: multimedia.videoName,
    logo: new URL(`/modules/assets/svgs/logos/${brandCode}.svg`, baseUrl).toString(),
    description: multimedia.description,
    thumbnailUrl: multimedia.posterImageUrl,
    uploadDate: multimedia.uploadDatetime.toString(),
    duration: multimedia.videoDuration,
    embedUrl: new URL(pathname, baseUrl).toString(),
  };
}
