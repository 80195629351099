import { useId, useRef } from 'react';
import type * as React from 'react';
import cx from 'classnames';
import { useIntersectionObserver } from 'usehooks-ts';
import { getIsReducedMotion } from '@dx-ui/utilities-accessibility';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import { BrandTextHeader } from '@dx-ui/osc-brand-text-header';
import { BrandLink } from '@dx-ui/osc-brand-buttons';
import type { TDynamicGrid } from './dynamic-grid';
import type { Link } from '@dx-ui/osc-link';
import DynamicGridItemWOM, { type TDynamicGridItemWOM } from './dynamic-grid-item-wom';
import { mapTextAlignToClassname } from '@dx-ui/osc-textual-block';
import { HeadingLevelProvider } from '@dx-ui/osc-heading-level';
import { useHasOverflow } from '@dx-ui/utilities-use-has-overflow';

type TDynamicGridWithoutListeners = Omit<TDynamicGrid, 'onViewItem' | 'onToggleModal'>;

export type TDynamicGridWOM = TDynamicGridWithoutListeners & {
  /** An array of TDynamicGridItem objects */
  items: TDynamicGridItemWOM[];
  /** */
  listSubheading?: string;
  /** Link CTA appears below component */
  link?: Link & { experimentationConfiguration?: CmsExperimentationConfiguration };
  /** */
  wrapperClassName?: string;
};

export const DocsTDynamicGridWOM: React.FC<React.PropsWithChildren<TDynamicGridWOM>> = () => null;

/**
 * Dynamic Grid With-Out-Modal (WOM) creates a grid of images with content underneath in either a 3x grid (item counts of 3, 5, 6, or 9) or a 4x grid (item counts of 4, 7, or 8).
 * Recommended grid item counts of 3 to 9.
 */
export const DynamicGridWOM: React.FC<TDynamicGridWOM & { brandCode?: string }> = ({
  imageAspectRatio,
  items: baseItems = [],
  isMobileVerticalStack = false,
  className = '',
  listHeadline,
  listSubheading,
  listDescription,
  textAlign = 'left',
  link,
  id,
  isAnimated = false,
  wrapperClassName,
  brandComponentTheme,
  ...props
}) => {
  const { ref, isIntersecting: inView } = useIntersectionObserver({
    threshold: [0.15],
    freezeOnceVisible: true,
  });
  const observedWrapper = !getIsReducedMotion() && isAnimated ? ref : null;
  const items = baseItems?.filter((item) => Boolean(item));
  const hasLinks = items.some((item) => item.link);
  const count = items.length;
  const headerId = useId();

  const scrollRef = useRef<HTMLDivElement>(null);
  const hasOverflow = useHasOverflow(scrollRef);
  const scrollSectionIsFocusable = hasOverflow && !hasLinks;

  if (!count) {
    return null;
  }

  const is369 = count % 3 === 0 || count === 5;
  const is4x = !is369 && count > 2 && (count % 2 === 0 || count === 7);
  const is2x = !is369 && (is4x || count % 2 === 0);
  const isOther = !is369 && !is4x && !is2x;

  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return (
    <section ref={observedWrapper}>
      <div
        className={
          wrapperClassName ||
          cx('container overflow-hidden sm:pe-2 md:py-8 lg:py-10', {
            [className]: !!className,
          })
        }
        data-testid="dynamicGridWOM"
        id={id}
      >
        <div className="dynamic-grid-wom-headline-wrapper">
          <div className="dynamic-grid-wom-headline">
            {listHeadline ? (
              <BrandTextHeader
                className={cx(mapTextAlignToClassname(textAlign), 'dynamic-grid-wom-header', {
                  'duration-1000 ease-in-out opacity-0 motion-reduce:transition-none motion-reduce:opacity-100':
                    isAnimated,
                  'translate-y-4': !inView && isAnimated,
                  'opacity-100 translate-y-0': inView && isAnimated,
                  '!text-text-inverse': isDark,
                  '!dynamic-grid-wom-header-light': isLight,
                })}
                id={`dynamic-grid-heading-${headerId}`}
              >
                {listHeadline}
              </BrandTextHeader>
            ) : null}

            {listSubheading ? (
              <span
                className={cx(mapTextAlignToClassname(textAlign), 'dynamic-grid-wom-subheader', {
                  'duration-1000 ease-in-out opacity-0 motion-reduce:transition-none motion-reduce:opacity-100':
                    isAnimated,
                  'translate-y-4': !inView && isAnimated,
                  'delay-150 opacity-100 translate-y-0': inView && isAnimated,
                  'text-text-inverse': isDark,
                  'dynamic-grid-wom-header-light': isLight,
                })}
              >
                {listSubheading}
              </span>
            ) : null}
          </div>

          {listDescription ? (
            <BrandTextBody
              className={cx(
                `dynamic-grid-wom-description pe-8 sm:pe-6`,
                mapTextAlignToClassname(textAlign),
                {
                  'duration-1000 ease-in-out opacity-0 motion-reduce:transition-none motion-reduce:opacity-100':
                    isAnimated,
                  'translate-y-4': !inView && isAnimated,
                  'delay-300 opacity-100 translate-y-0': inView && isAnimated,
                  'text-text-inverse': isDark,
                }
              )}
              {...props}
              brandComponentTheme={brandComponentTheme}
            >
              {listDescription}
            </BrandTextBody>
          ) : null}
        </div>

        <div className="dynamic-grid-wom-grid-wrapper">
          <div
            data-testid="dynamicGridItemsWrap"
            className={cx(
              'grid-wrapper -mx-4 grid gap-4 overflow-x-auto overflow-y-hidden px-4 py-2 md:-mx-8 md:ps-8',
              `auto-cols-[85%] sm:me-0 sm:w-full sm:grid-flow-row sm:overflow-x-hidden sm:pe-0`,
              isMobileVerticalStack ? 'grid-flow-row grid-cols-1' : 'grid-flow-col',
              is369 ? 'sm:grid-cols-3' : null,
              is2x ? 'sm:grid-cols-2' : null,
              is4x ? 'lg:grid-cols-4' : null,
              isOther ? 'sm:grid-cols-2 lg:grid-cols-4' : null,
              {
                'text-text-inverse': isDark,
                'dynamic-grid-wom-grid-wrap-light': isLight,
              }
            )}
            tabIndex={scrollSectionIsFocusable ? 0 : -1}
            aria-labelledby={`dynamic-grid-heading-${headerId}`}
            ref={scrollRef}
          >
            <HeadingLevelProvider shouldIncreaseLevel={!!listHeadline}>
              {items.map((item, itemIndex) => (
                <DynamicGridItemWOM
                  key={`dynamic-grid-item-wom${item.id}`}
                  isAnimated={isAnimated}
                  brandComponentTheme={brandComponentTheme}
                  imageAspectRatio={imageAspectRatio}
                  position={itemIndex + 1}
                  listHeadline={listHeadline}
                  count={count}
                  {...item}
                />
              ))}
            </HeadingLevelProvider>
          </div>
        </div>
        {link?.adaDescription && link?.url && link?.label ? (
          <div className="relative flex w-full justify-center">
            <BrandLink
              label={link.label}
              isNewWindow={link.isNewWindow}
              showNewWindowIcon={link.isNewWindow}
              url={link.url}
              brandComponentTheme={brandComponentTheme}
              data-conductrics-goal={link.experimentationConfiguration?.goal}
              data-conductrics-value={link.experimentationConfiguration?.value}
            />
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default DynamicGridWOM;
