import { useState, useRef } from 'react';
import cx from 'classnames';
import { useRect } from '@dx-ui/utilities-use-rect';
import { DialogWithContent } from '../dialog-with-content';
import { ResponsiveImage, getCurrentAspectRatioAndUrl } from '@dx-ui/osc-responsive-image';
import type { TLayout } from '../common.types';
import type { TFullWidthImage } from './full-width-image.types';
import { FullWidthImageDialogAspectRatio } from './full-width-image.types';
import { Icon } from '@dx-ui/osc-icon';
import { ANALYTICS_GLOBAL_CLICK_EVENT, ImageFunction, trackEvent } from '@dx-ui/config-metrics';
import { generateFullWidthMediaMetricsParams } from './libs/utils/full-width-image-analytics';

export type FullWidthImageProps = TLayout & TFullWidthImage;

/**
 * FullWidthImage renders an image at the full width of the content, includes text overlay that opens a modal.
 *
 * Multiple imageUrls are provided, and displayed conditionally at different breakpoints. Clicking the FullWidthImage will open a DialogWithContent
 */
export const FullWidthImage: React.FC<FullWidthImageProps> = ({
  id,
  desktopImageUrl,
  tabletImageUrl,
  mobileImageUrl,
  imageAltText,
  captionData,
  imageOverlayButtonText,
  title,
  description,
  link,
  overlayButtonClicked,
  brandComponentTheme = 'HH',
  ...props
}) => {
  const [showInfoContainer, setShowInfoContainer] = useState(false);
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });
  const [imgLoaded, setImgLoaded] = useState(false);

  const isThemeAdded = !!brandComponentTheme;

  const handleImageOverlayButtonClick = () => {
    setShowInfoContainer(true);
    overlayButtonClicked && overlayButtonClicked();
    trackEvent(
      ANALYTICS_GLOBAL_CLICK_EVENT,
      generateFullWidthMediaMetricsParams({
        headline: title,
        itemTitle: imageOverlayButtonText,
        imageFunction: ImageFunction.Modal,
      })
    );
  };
  const handleCloseButtonClick = () => {
    setShowInfoContainer(false);
  };

  if (!desktopImageUrl || !tabletImageUrl || !mobileImageUrl) {
    return null;
  }

  const { imageUrl, aspectRatio } = getCurrentAspectRatioAndUrl({
    width: rect?.width || 0,
    imageUrlMobile: mobileImageUrl,
    imageUrlTablet: desktopImageUrl,
    imageUrlDesktop: desktopImageUrl,
    aspectRatioMobile: '3:2',
    aspectRatioTablet: '18:5',
    aspectRatioDesktop: '18:5',
  });

  return (
    <div
      id={id}
      ref={ref}
      data-testid="fullWidthImage"
      className={cx('container pb-10 pt-16 md:pl-8 md:pr-6 lg:pb-16 lg:pt-20', {
        'pt-20 pb-14 lg:pt-24 lg:pb-20': isThemeAdded,
      })}
    >
      <div
        data-testid="fullwidth-image-wrap"
        className="image-corner-radius relative flex flex-col justify-center overflow-hidden"
      >
        {imageOverlayButtonText ? (
          <div className={cx('absolute flex size-full items-end', { 'z-2': imgLoaded })}>
            <div className="from-bg-inverse size-full bg-gradient-to-t to-transparent">
              <button
                className="brand-es:font-headline brand-es:font-normal brand-gu:font-headline brand-gu:font-normal brand-ou:font-headline brand-ou:font-normal text-text-overlay brand-ey:text-text-inverse brand-ou:text-text-inverse absolute bottom-2 right-4 flex h-14 cursor-pointer items-center border-0 text-right text-xl font-bold leading-5 md:bottom-8 lg:right-8 lg:text-4xl"
                type="button"
                onClick={handleImageOverlayButtonClick}
                data-testid="overlayButton"
              >
                <span>{imageOverlayButtonText}&nbsp;</span>
                <Icon
                  className="-mx-4 size-12 xl:size-16 rtl:-scale-x-100"
                  name="arrow-small-right"
                />
              </button>
            </div>
          </div>
        ) : null}

        <ResponsiveImage
          id={`${id}-img`}
          aspectRatio={aspectRatio}
          imageUrl={imageUrl}
          altText={imageAltText || ''}
          width={rect?.width ?? 0}
          onImgLoaded={() => setImgLoaded(true)}
          captionData={captionData}
          onClick={() =>
            trackEvent(
              ANALYTICS_GLOBAL_CLICK_EVENT,
              generateFullWidthMediaMetricsParams({
                headline: title,
                imageFunction: ImageFunction.Link,
                itemTitle: captionData?.caption,
              })
            )
          }
        />
      </div>

      {imageOverlayButtonText ? (
        <DialogWithContent
          data-testid="fullWidthImageModalDialog"
          id="fullWidthImageModalOverlay"
          isOpen={showInfoContainer}
          containerAspectRatio={FullWidthImageDialogAspectRatio}
          imageAspectRatio={FullWidthImageDialogAspectRatio}
          imageUrls={{ desktopImageUrl, mobileImageUrl, tabletImageUrl }}
          captionData={captionData}
          imageAltText={`${imageAltText} modal`}
          title={title}
          headline={title}
          content={description}
          link={link}
          onClose={handleCloseButtonClick}
          captionMetricsOnClick={() =>
            trackEvent(
              ANALYTICS_GLOBAL_CLICK_EVENT,
              generateFullWidthMediaMetricsParams({
                headline: title,
                imageFunction: ImageFunction.Link,
                itemTitle: captionData?.caption,
              })
            )
          }
          brandLinkMetricsOnClick={() =>
            trackEvent(
              ANALYTICS_GLOBAL_CLICK_EVENT,
              generateFullWidthMediaMetricsParams({
                headline: title,
                imageFunction: ImageFunction.Link,
                itemTitle: link?.label,
              })
            )
          }
          {...props}
        />
      ) : null}
    </div>
  );
};
